<template>
  <div id="app" class="ixl">
    <!-- <div
      v-if="generalMsg.text != ''"
      class="top-banner"
      :style="
        `background-color:` +
        generalMsg.background_color +
        `; color:` +
        generalMsg.text_color +
        `;`
      "
    >
      <span class="texttop" v-html="generalMsg.text"></span>
    </div> -->
    <Layout />
  </div>
</template>
<script>
import { isServer } from "@storefront/core/helpers";
// import Header from "@/esf_weert_mobilesupplies/core/components/partial/headers/HeaderDefault.vue";
// import Footer from "@/esf_weert_mobilesupplies/core/components/partial/footers/FooterDefault.vue";
import Layout from "@/esf_weert_mobilesupplies/layouts/MainLayout.vue";

import config from "@config";

const contentList = [
  "bottom_info", // pending
  "category-node-11183",
  "category-node-11184",
  "category-node-11185",
  "category-node-11186",
  "catlist-usp",
  "contactinformatie", // Done
  "cookie_restriction_notice_block",
  "extra-actie-banner",
  "header-contact-info", // pending
  "header-top-links", // Done
  "mobile_footerlinks", // pending
  "mobile_home_highlights",
  "mobile_seo_footerlinks",
  "nieuwsbrief",
  "product-extrainfo-prijsvak",
  "rmapolicy",
  "sellingpoints",
  "SEOFIX",
  "subcategory_listing",
  "subcategory_listing_amasty",
  "subcategory_listing_small",
  "subcategory_listing_small_amasty",
  "subcategory_listing_small_only_nav_amasty",
  "subcategory_listing_small_only_no_nav_amasty",
  "voordelen_zakelijk",
  "winkelwagen-extra-info",
];

const sliders = ["home_banner", "popular"];

export default {
  name: "App",
  metaInfo() {
    return {
      htmlAttrs: { lang: this.$i18n.locale },
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
      ],
      titleTemplate: "%s | " + config.app_name,
    };
  },
  computed: {
    cmsDefaultPageContent() {
      return this.$store.state.cmsPage.default;
    },
    generalMsg() {
      return this.$store.state.messages.generalMsg;
    },
  },
  watch: {
    "$store.state.messages.msgItems": async function () {
      const msgs = await this.$store.dispatch("messages/giveLastMessages");
      msgs.forEach(function (msg) {
        this.makeToast(msg);
      }, this);
    },
    "$store.state.user.isLoggedIn": async function (isLoggedIn) {
      if (isLoggedIn == true) {
        this.$store.dispatch("wishlist/loadLoginWishlist");
      } else {
        this.$store.dispatch("wishlist/loadWishlist");
      }
    },
  },
  components: {
    Layout,
  },
  async serverPrefetch() {
    // await this.$store.dispatch("home/loadMobileSuppliesProducts");

    await this.$store.dispatch("cmsPage/loadDefault", {
      id: "home",
    });
    await this.$store.dispatch("cmsBlock/multiple", {
      key: "identifier",
      value: contentList,
    });
    await this.$store.dispatch("sliders/multiple", {
      key: "identifier",
      value: sliders,
    });

    // await this.$store.dispatch("home/loadKiyohRatings");

    await this.$store.dispatch("home/loadHomeBlogs", {
      category_id: "1",
    });

    await this.$store.dispatch("user/loadCountries");
    await this.$store.dispatch("menu/loadMenu");
    // await this.$store.dispatch("home/loadSpecialSelectedProducts");
    await this.$store.dispatch("home/loadHomeNewSofas");
    await this.$store.dispatch("home/loadLatestTrendsProducts");
    await this.$store.dispatch("home/loadHomeBestSellers");

    await this.$store.dispatch("product/loadBrandSlider");
    // await this.$store.dispatch("product/loadBrands");
    await this.$store.dispatch("product/productReviewRatingsMetadata");
    await this.$store.dispatch("faq/load");
    await this.$store.dispatch("forms/loadForms");
    await this.$store.dispatch("blog_additional/load");
    await this.$store.dispatch("blog_additional/loadCustomerService");

    /*
    await this.$store.dispatch("messages/loadGeneralMsg");
    await this.$store.dispatch("product/loadBrandSlider");
    await this.$store.dispatch("menu/loadMenu");
    await this.$store.dispatch("user/loadCountries");
    await this.$store.dispatch("product/productReviewRatingsMetadata");
    await this.$store.dispatch("stores/load");
    await this.$store.dispatch("home/loadBundles");
    */
  },
  async created() {
    if (!isServer) {
      await this.$store.dispatch("user/loadUser");
      // if (!document.getElementById("Cookiebot")) {
      //   var script = document.createElement("script");
      //   script.src = "https://consent.cookiebot.com/uc.js";
      //   script.dataset.cbid = "73c87479-fd79-4fe8-a149-ce0ca9dbf21a";
      //   script.async = true;
      //   script.id = "Cookiebot";
      //   document.head.appendChild(script); //or something of the likes
      // }
      // this.$gtm.enable(true);
    }
  },
  async beforeMount() {
    if (this.$store.getters["user/getIsLoggedIn"] == true) {
      if (this.$route.name === "login") {
        return this.$router.push({
          name: "account",
        });
      } else if (this.$route.name === "create-account") {
        return this.$router.push({
          name: "account",
        });
      } else if (this.$route.name === "en-login") {
        return this.$router.push({
          name: "en-account",
        });
      } else if (this.$route.name === "en-create-account") {
        return this.$router.push({
          name: "en-account",
        });
      }
    } else {
      if (this.$route.name === "account") {
        return this.$router.push({
          name: "login",
        });
      } else if (this.$route.name === "en-account") {
        return this.$router.push({
          name: "en-login",
        });
      }
    }
    await this.$store.dispatch("user/loadUser");
    if (this.$store.getters["cart/getCartIsLoaded"] == false) {
      await this.$store.dispatch("cart/loadCart");
    }
    if (this.$store.getters["wishlist/getWishlistId"] == null) {
      await this.$store.dispatch("wishlist/loadWishlist");
    }
  },
  methods: {
    makeToast(msg) {
      this.$bvToast.toast(msg.text, {
        title: msg.title,
        variant: msg.type,
        autoHideDelay: 5000,
        toaster: "b-toaster-top-full",
        solid: true,
        append: true,
      });
    },
  },
};
</script>

<style lang="scss">
.toast-body {
  // text-align: center;
  font-size: 12px;
  font-weight: bold;
}
</style>
